import { Pipe, PipeTransform } from '@angular/core';
import { PresenceMember } from '@dougs/core/socket';

@Pipe({
  name: 'presenceMemberSlice',
  standalone: true,
})
export class PresenceMemberSlicePipe implements PipeTransform {
  transform(presenceMembers: PresenceMember[], isCompact: boolean): number {
    if (isCompact) {
      if (presenceMembers.length > 1) {
        return 0;
      }

      return 1;
    }

    if (presenceMembers.length > 3) {
      return 2;
    }

    return presenceMembers.length;
  }
}
