import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UpdatePaymentCardComponentComponentService } from '../../services/update-payment-card.component.service';

@Component({
  selector: 'dougs-update-payment-card',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './update-payment-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UpdatePaymentCardComponentComponentService],
})
export class UpdatePaymentCardComponent {
  constructor(public readonly updatePaymentCardComponentComponentService: UpdatePaymentCardComponentComponentService) {}
}
