@if (updatePaymentCardComponentComponentService.refreshPendingBillingInvoices$ | async) {}

@if (updatePaymentCardComponentComponentService.daysBeforePayment$ | async; as daysBeforePayment) {
  <div class="notification-reminder is-warning">
    <p class="color-primary-700">
      Votre premier paiement arrive {{ daysBeforePayment }}, avez-vous mis à jour votre carte bancaire ?
      <a
        class="color-warning pointer"
        rel="noopener"
        (click)="updatePaymentCardComponentComponentService.onModifyPaymentCard()"
        >Modifier ma carte</a
      >
    </p>
  </div>
}
