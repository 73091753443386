import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Company } from '@dougs/company/dto';
import { AppConfig } from '@dougs/core/config';
import { User } from '@dougs/user/dto';

@Injectable({
  providedIn: 'root',
})
export class InteractionsHttpService {
  constructor(private readonly http: HttpClient) {}

  postInteractions(loggedInUserId: number, activeUser: User, activeCompany: Company): Observable<void> {
    return this.http.post<void>(`${AppConfig.settings.legacyApiServerUrl}/users/${loggedInUserId}/interactions`, {
      companyId: activeCompany.id,
      userId: activeUser.id,
    });
  }
}
