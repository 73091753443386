import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostBinding,
  HostListener,
  Input,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { CompanyDemoStateService, CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { RoutingService, URL } from '@dougs/core/routing';
import { toPromise } from '@dougs/core/utils';
import { LogoComponent } from '@dougs/ds';
import { LeadStateService } from '@dougs/partnership/shared';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { UserStateService } from '@dougs/user/shared';
import { PresenceMembersComponent } from './presence-members.component';

@Component({
  selector: 'dougs-sidebar-menu',
  templateUrl: './sidebar-menu.component.html',
  styleUrls: ['./sidebar-menu.component.scss'],
  standalone: true,
  imports: [CommonModule, LogoComponent, PresenceMembersComponent],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarMenuComponent implements AfterViewInit {
  hasScrollbar = false;

  @ViewChild('sidebarMenu') sidebarMenu!: ElementRef;

  @Input()
  @HostBinding('class.is-compact')
  isCompact = false;

  @Input() logoName?: string;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly companyStateService: CompanyStateService,
    private readonly companyServicesStateService: CompanyServicesStateService,
    public readonly companyDemoStateService: CompanyDemoStateService,
    private readonly userStateService: UserStateService,
    private readonly router: Router,
    private readonly leadStateService: LeadStateService,
    private readonly routingService: RoutingService,
    private readonly metricsService: MetricsService,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize(): void {
    this.addClassIfMenuHasScrollbar();
  }

  ngAfterViewInit(): void {
    this.addClassIfMenuHasScrollbar();
  }

  addClassIfMenuHasScrollbar(): void {
    if (this.sidebarMenu) {
      const element = this.sidebarMenu.nativeElement;
      this.hasScrollbar = element.scrollHeight > element.clientHeight;
      this.cdr.markForCheck();
    }
  }

  async goToHomePage(): Promise<void> {
    if (this.companyStateService.activeCompany?.isCreated) {
      this.metricsService.pushMixpanelEvent(
        `Menu ${
          this.companyServicesStateService.services?.accounting?.isActive ||
          (!this.companyServicesStateService.services?.accounting?.isActive &&
            !!this.companyServicesStateService.services?.accounting?.isTrialing)
            ? `Accounting`
            : `Invoicing`
        } Dougs Logo Link Clicked`,
      );

      await this.router.navigateByUrl(this.routingService.createUrl([URL.ACCOUNTING]).toString());
      return;
    }

    /**
     * Cas des entreprises en création:
     * Si elle vient d'un partenaire (Captain contrat/LegalStart par exemple), on la redirige vers le blankstate accounting
     * Sinon sur la page de création d'entreprise
     */
    await this.leadStateService.refreshLead(this.userStateService.activeUser.email);
    if (await toPromise(this.leadStateService.lead$)) {
      await this.router.navigateByUrl(this.routingService.createUrl([URL.ACCOUNTING]).toString());
    } else {
      await this.router.navigateByUrl(this.routingService.createUrl([URL.ONBOARDING_CREATION]).toString());
    }
  }
}
