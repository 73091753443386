import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PresenceMember, PresenceMemberStateService } from '@dougs/core/socket';
import { UserStateService } from '@dougs/user/shared';
import { AccountingFirmStateService } from '@dougs/white-label/shared';

@Injectable()
export class PresenceMembersComponentService {
  shouldShowPresenceMember$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map((loggedInUser) => this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId)),
  );

  presenceMembersToShow$: Observable<PresenceMember[]> = this.presenceMemberStateService.presenceMembers$.pipe(
    map((presenceMembers) => this.getPresenceMembersToShow(presenceMembers)),
  );

  constructor(
    private readonly userStateService: UserStateService,
    private readonly accountingFirmStateService: AccountingFirmStateService,
    private readonly presenceMemberStateService: PresenceMemberStateService,
  ) {}

  private getPresenceMembersToShow(presenceMembers: PresenceMember[]): PresenceMember[] {
    return presenceMembers.filter(
      (presenceMember) =>
        Number(presenceMember.id) !== this.userStateService.activeUser.id &&
        (this.userStateService.loggedInUser.isAccountantOrAdmin || !presenceMember.info.shouldBeHidden),
    );
  }
}
