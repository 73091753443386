<div class="user-menu-dropdown" [ngClass]="{ 'user-menu-dropdown--compact': isCompact }">
  <dougs-user-menu-admin-dropdown *ngIf="(userStateService.loggedInUser$ | async)?.isAccountantOrAdmin" />
  <ng-container *ngIf="companyStateService.activeCompany$ | async as activeCompany">
    <div
      class="user-menu-dropdown__item"
      *ngFor="let company of (userStateService.companies$ | async) || [activeCompany]; trackBy: 'id' | trackBy"
    >
      <a
        class="px-8 py-16"
        (click)="userMenuComponentService.openChangeCompanyLoaderModal(company.id)"
        [routerLink]="company.id | replaceActiveCompanyIdInUrl | async"
      >
        <i class="fal fa-building user-menu-dropdown__item__avatar"></i>
        <div class="user-menu-dropdown__item__right">
          <div class="ml-8 user-menu-dropdown__item__company">
            <h6 class="user-menu-dropdown__item__company--name">
              {{ company.brandName || company.legalName || (userStateService.activeUserFullName$ | async) }}
            </h6>
          </div>
          <i
            *ngIf="activeCompany.id === company.id"
            class="fal fa-check color-primary ml-8 user-menu-dropdown__item__icon"
          ></i>
        </div>
      </a>
    </div>
    @if (!activeCompany.isCreated) {
      <div class="pointer user-menu-dropdown__item">
        <a class="p-8" [routerLink]="URL.SETTINGS | createUrl | async">
          <i class="fal fa-gear mr-8"></i>
          <p class="small">Paramètres</p>
        </a>
      </div>
    }
  </ng-container>
  <!--<div
    class="pointer user-menu-dropdown__item user-menu-dropdown__item--add p-8"
    (click)="userMenuComponentService.openAddCompanyModal()"
  >
    <i class="fal fa-plus-circle mr-8"></i>
    <p class="small">Ajouter une entreprise</p>
  </div>-->
  <div
    class="pointer user-menu-dropdown__item user-menu-dropdown__item--sign-out px-8 py-16"
    (click)="userMenuComponentService.goToLogout()"
  >
    <i class="fal fa-sign-out mr-8"></i>
    <p class="small">Se déconnecter</p>
  </div>
</div>
