import { Directive, HostBinding, Input } from '@angular/core';

@Directive({
  selector: '[dougsSidebarMenuSeparator]',
  standalone: true,
})
export class SidebarMenuSeparatorDirective {
  @HostBinding('class')
  private readonly classes = 'sidebar-menu-separator';

  @Input()
  @HostBinding('class.is-compact')
  isCompact = false;
}
