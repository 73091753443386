import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UserStateService } from '@dougs/user/shared';
import { TrialComponentService } from '../../services/trial.component.service';

@Component({
  selector: 'dougs-trial',
  templateUrl: './trial.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule],
  providers: [TrialComponentService],
})
export class TrialComponent {
  constructor(
    public readonly trialComponentService: TrialComponentService,
    public readonly userStateService: UserStateService,
  ) {}
}
