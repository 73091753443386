import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonComponent, WistiaVideoDirective } from '@dougs/ds';
import { ChecklistComponentService } from '../../services/checklist.component.service';

@Component({
  selector: 'dougs-checklist-tip-details',
  standalone: true,
  imports: [CommonModule, ButtonComponent, WistiaVideoDirective],
  templateUrl: './checklist-tip-details.component.html',
  styleUrls: ['./checklist-tip-details.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistTipDetailsComponent {
  constructor(public readonly checklistComponentService: ChecklistComponentService) {}
}
