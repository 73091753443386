import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { RoutingPipe, URL } from '@dougs/core/routing';
import { UserStateService } from '@dougs/user/shared';
import { AccountingSurveyReminderComponentService } from '../../services/accounting-survey-reminder.component.service';

@Component({
  selector: 'dougs-accounting-survey-reminder',
  templateUrl: './accounting-survey-reminder.component.html',
  standalone: true,
  imports: [CommonModule, RouterModule, RoutingPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AccountingSurveyReminderComponentService],
})
export class AccountingSurveyReminderComponent {
  protected readonly URL = URL;

  constructor(
    public readonly accountingSurveyReminderComponentService: AccountingSurveyReminderComponentService,
    public readonly userStateService: UserStateService,
  ) {}
}
