import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import {
  ControlFormFieldDirective,
  ErrorFormFieldDirective,
  FormFieldComponent,
  LabelFormFieldDirective,
  LogoComponent,
} from '@dougs/ds';
import { AddCompanyModalComponentService } from '../../../services/modals/add-company-modal.component.service';

@Component({
  selector: 'dougs-add-company-modal-second-step',
  standalone: true,
  imports: [
    CommonModule,
    LogoComponent,
    FormFieldComponent,
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    ReactiveFormsModule,
    ErrorFormFieldDirective,
  ],
  templateUrl: './add-company-modal-second-step.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCompanyModalSecondStepComponent {
  constructor(
    public readonly formService: FormService,
    public readonly addCompanyModalComponentService: AddCompanyModalComponentService,
  ) {}
}
