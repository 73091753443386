<div class="text-center">
  <h5>Naviguez facilement entre vos différentes entreprise</h5>
  <p class="small">
    Pour gérer plusieurs entreprises facilement, reliez-les entre elles sur Dougs.
    <br />
    Choisissez une entreprise principale et utilisez les identifiants de cette entreprise pour un accès unique chez
    Dougs.
  </p>
  <div class="add-company mt-16">
    <div class="add-company__option p-24 text-center" (click)="addCompanyModalComponentService.goToNextStep()">
      <img src="images/illustrations/multi-account-have-account.png" alt="" />
      <h6 class="mt-16">J'ai déjà un compte Dougs pour mon entreprise</h6>
    </div>
    <div class="add-company__option p-24 text-center" (click)="addCompanyModalComponentService.goToNextStep()">
      <img src="images/illustrations/multi-account-not-have-account.png" alt="" />
      <h6 class="mt-16">Je n’ai pas encore de compte Dougs pour mon entreprise</h6>
    </div>
  </div>
</div>
