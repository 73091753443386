import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { BlankStateComponent, TrackByPipe } from '@dougs/ds';
import { ChecklistComponentService, ChecklistTab } from '../../services/checklist.component.service';
import { ChecklistCategoryComponent } from '../checklist-category/checklist-category.component';

const ANIMATION = '300ms ease-in-out';

@Component({
  selector: 'dougs-checklist-tip-list',
  standalone: true,
  imports: [CommonModule, TrackByPipe, BlankStateComponent, ChecklistCategoryComponent],
  templateUrl: './checklist-tip-list.component.html',
  styleUrls: ['./checklist-tip-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':leave', [
        style({ marginBottom: '16px' }),
        animate(
          ANIMATION,
          keyframes([
            style({ transform: 'translateX({{ position }})', marginTop: '*', opacity: 0, offset: 0.5 }),
            style({ height: 0, marginTop: 0, marginBottom: 0, offset: 1 }),
          ]),
        ),
      ]),
    ]),
    trigger('inOutFromLeft', [
      transition(':enter', [
        style({ position: 'absolute', top: 0, width: '100%', transform: 'translateX(-100%)' }),
        animate(ANIMATION, style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ position: 'absolute', top: 0, width: '100%', bottom: 0, left: 0 }),
        animate(ANIMATION, style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
    trigger('inOutFromRight', [
      transition(':enter', [
        style({
          position: 'absolute',
          top: 0,
          width: '100%',
          transform: 'translateX(100%)',
        }),
        animate(ANIMATION, style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ position: 'absolute', top: 0, width: '100%' }),
        animate(ANIMATION, style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class ChecklistTipListComponent {
  constructor(public readonly checklistComponentService: ChecklistComponentService) {}

  ChecklistTab = ChecklistTab;
}
