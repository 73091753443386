import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountingOnboardingChecklistTip } from '@dougs/accounting/onboarding/dto';
import { TooltipDirective } from '@dougs/ds';

@Component({
  selector: 'dougs-checklist-tip',
  standalone: true,
  imports: [CommonModule, TooltipDirective],
  templateUrl: './checklist-tip.component.html',
  styleUrls: ['./checklist-tip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChecklistTipComponent {
  @Input() tip: AccountingOnboardingChecklistTip | undefined;
  @Input() isFirst = false;
  @Output() checkboxClicked = new EventEmitter<void>();
  @Output() tipClicked = new EventEmitter<void>();
}
