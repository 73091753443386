<div *ngIf="trialComponentService.trialRemainingDays$ | async as nbDays" class="notification-reminder is-warning">
  <p class="color-gray-700">
    {{ userStateService.activeUserFirstName$ | async }}, il vous reste
    <b>{{ nbDays }} jour{{ nbDays > 1 ? 's' : '' }}</b>
    sur votre période d'essai.
  </p>
  <p (click)="trialComponentService.onShowPlans()" class="underline pointer color-warning">
    <b>Voir les offres</b>
  </p>
</div>
