import { Injectable, Signal, signal, WritableSignal } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ModalRef } from '@dougs/ds';

@Injectable()
export class AddCompanyModalComponentService {
  private readonly currentStep: WritableSignal<number> = signal(1);
  currentStep$: Signal<number> = this.currentStep.asReadonly();

  formGroup: FormGroup = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [Validators.required]),
  });

  constructor(private readonly modalRef: ModalRef) {}

  get email(): FormControl {
    return this.formGroup.get('email') as FormControl;
  }

  get password(): FormControl {
    return this.formGroup.get('password') as FormControl;
  }

  goToNextStep(): void {
    this.currentStep.set(this.currentStep() + 1);

    if (this.currentStep() === 3) {
      setTimeout(() => {
        this.modalRef.close();
      }, 5000);
    }
  }

  onSubmit(): void {
    this.formGroup.markAllAsTouched();
    if (this.formGroup.valid) {
      this.goToNextStep();
    }
  }
}
