import { animate, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { ChecklistComponentService } from '../services/checklist.component.service';
import { ChecklistTipDetailsComponent } from './checklist-tip-details/checklist-tip-details.component';
import { ChecklistTipListComponent } from './checklist-tip-list/checklist-tip-list.component';

const ANIMATION = '300ms cubic-bezier(0.25, 0.8, 0.25, 1)';

@Component({
  selector: 'dougs-checklist',
  standalone: true,
  imports: [CommonModule, ChecklistTipListComponent, ChecklistTipDetailsComponent],
  providers: [ChecklistComponentService],
  templateUrl: './checklist.component.html',
  styleUrls: ['./checklist.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('inOutAnimation', [
      transition(':enter', [
        style({ left: '-160px', opacity: 0 }),
        animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ left: '*', opacity: 1 })),
      ]),
      transition(':leave', [animate('400ms cubic-bezier(0.25, 0.8, 0.25, 1)', style({ left: '-160px', opacity: 0 }))]),
    ]),
    trigger('inOutFromLeft', [
      transition(':enter', [
        style({ position: 'absolute', top: 0, left: 0, transform: 'translateX(-100%)' }),
        animate(ANIMATION, style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ position: 'absolute', top: 0, left: 0 }),
        animate(ANIMATION, style({ transform: 'translateX(-100%)' })),
      ]),
    ]),
    trigger('inOutFromRight', [
      transition(':enter', [
        style({ position: 'absolute', top: 0, left: 0, transform: 'translateX(100%)' }),
        animate(ANIMATION, style({ transform: 'translateX(0)' })),
      ]),
      transition(':leave', [
        style({ position: 'absolute', top: 0, left: 0 }),
        animate(ANIMATION, style({ transform: 'translateX(100%)' })),
      ]),
    ]),
  ],
})
export class ChecklistComponent {
  @Input() isOpen = false;
  @Input() isCompact = false;
  @Output() close = new EventEmitter<void>();

  constructor(public readonly checkListComponentService: ChecklistComponentService) {}
}
