import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ModalContentDirective } from '@dougs/ds';
import { AddCompanyModalComponentService } from '../../../services/modals/add-company-modal.component.service';

@Component({
  selector: 'dougs-add-company-modal-first-step',
  standalone: true,
  imports: [CommonModule, ModalContentDirective],
  templateUrl: './add-company-modal-first-step.component.html',
  styleUrls: ['./add-company-modal-first-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCompanyModalFirstStepComponent {
  constructor(public readonly addCompanyModalComponentService: AddCompanyModalComponentService) {}
}
