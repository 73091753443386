<div class="notification-reminder is-success">
  <p class="color-green-700">Vous souhaitez <b>lancer votre entreprise</b> ? Dougs vous accompagne.</p>
  <p
    [ngClass]="{ 'underline pointer': !companyCreationXSellComponentService.isLoading$() }"
    (click)="companyCreationXSellComponentService.startCreationService()"
    class="color-green-500"
  >
    <b>
      {{ companyCreationXSellComponentService.isLoading$() ? 'Demande de création en cours...' : 'Créer mon entreprise'
      }}
    </b>
  </p>
</div>
