import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';

@Component({
  selector: 'dougs-add-company-modal-third-step',
  standalone: true,
  imports: [CommonModule, LottieComponent],
  templateUrl: './add-company-modal-third-step.component.html',
  styleUrls: ['./add-company-modal-third-step.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AddCompanyModalThirdStepComponent {
  animation: AnimationOptions = {
    path: 'animations/link-company.json',
  };
}
