import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CompanyCreationXSellComponentService } from '../../services/company-creation-x-sell.component.service';

@Component({
  selector: 'dougs-company-creation-xsell',
  templateUrl: './company-creation-xsell.html',
  styleUrls: ['./company-creation-xsell.scss'],
  standalone: true,
  imports: [CommonModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CompanyCreationXSellComponentService],
})
export class CompanyCreationXsellComponent {
  constructor(public readonly companyCreationXSellComponentService: CompanyCreationXSellComponentService) {}
}
