<header class="checklist-tip-header p-16">
  <div (click)="checklistComponentService.closeTip()" class="checklist-tip-header__button pointer">
    <i class="fal fa-chevron-left mr-4"></i>
    <p class="small color-primary-700">Retour</p>
  </div>
</header>

<div *ngIf="checklistComponentService.currentTip$() as tip" class="checklist-tip-content pt-24 pb-32 px-32">
  <img *ngIf="tip.image" [src]="tip.image" class="mb-24" alt="Illustration" />
  <div
    *ngIf="tip.video"
    [wistiaVideo]="tip.video"
    (click)="$event.stopPropagation()"
    class="checklist-tip-content__video mb-24"
  ></div>
  <h5 [innerHTML]="tip.title" class="mb-8"></h5>
  <div class="checklist-tip-content__text">
    <p
      *ngFor="let text of tip.content; let first = first; trackBy: checklistComponentService.trackByIndex"
      [innerHTML]="text"
      [class.mt-16]="!first"
      class="small color-primary-700"
    ></p>
  </div>
  <dougs-button
    [fullWidth]="true"
    (click)="checklistComponentService.handleTipClick(tip)"
    color="secondary"
    class="mt-24"
  >
    <i *ngIf="!tip.archivedAt" class="fal fa-check mr-8"></i>
    {{ tip.archivedAt ? 'Déplacer dans les conseils en cours' : 'Marquer comme terminé' }}
  </dougs-button>
</div>
