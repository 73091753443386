import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, Validators } from '@angular/forms';
import { BehaviorSubject, concatMap, lastValueFrom, Observable, startWith, tap } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { AppConfig } from '@dougs/core/config';
import { Attachment, FileService } from '@dougs/core/files';
import { LoggerService } from '@dougs/core/logger';
import { Task } from '@dougs/task/dto';
import { SupportTasksStateService } from '@dougs/task/shared';
import { OpenTaskModalService } from '@dougs/task/ui';
import { UserStateService } from '@dougs/user/shared';

@Injectable()
export class SupportModalComponentService {
  searchFormGroup = new FormGroup({
    search: new FormControl(''),
    targetFilters: new FormControl('activeCompany'),
    completedFilters: new FormControl('actives'),
  });

  creationFormGroup = new FormGroup({
    title: new FormControl('', [Validators.required]),
    actionsPath: new FormControl('', [Validators.required]),
    actualResult: new FormControl('', [Validators.required]),
    expectedResult: new FormControl('', [Validators.required]),
    workaroundAvailable: new FormControl(true, [Validators.required]),
    workaroundDescription: new FormControl('', [Validators.required]),
    customerImpactLevel: new FormControl('', [Validators.required]),
    financialRiskLevel: new FormControl('', [Validators.required]),
  });

  files: File[] = [];
  tempAttachments: Attachment[] = [];

  private readonly isLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  isLoading$: Observable<boolean> = this.isLoading.asObservable();

  workaroundAvailableValueChanges$: Observable<boolean> = this.workaroundAvailable.valueChanges.pipe(
    tap((value: boolean) => {
      if (!value) {
        this.workaroundDescription.disable();
      } else {
        this.workaroundDescription.enable();
      }
    }),
  );

  searchFormGroupValueChanges$: Observable<void> = this.searchFormGroup.valueChanges.pipe(
    startWith(''),
    distinctUntilChanged(),
    debounceTime(500),
    tap(() => this.isLoading.next(true)),
    concatMap(() => this.refreshSearchedTasks()),
    tap(() => this.isLoading.next(false)),
  );

  constructor(
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly supportTasksStateService: SupportTasksStateService,
    private readonly http: HttpClient,
    private readonly logger: LoggerService,
    private readonly window: Window,
    private readonly fileService: FileService,
    private readonly openTaskModalService: OpenTaskModalService,
  ) {}

  get targetFilters(): AbstractControl {
    return this.searchFormGroup.get('targetFilters') as AbstractControl;
  }

  get completedFilters(): AbstractControl {
    return this.searchFormGroup.get('completedFilters') as AbstractControl;
  }

  get search(): AbstractControl {
    return this.searchFormGroup.get('search') as AbstractControl;
  }

  get title(): AbstractControl {
    return this.creationFormGroup.get('title') as AbstractControl;
  }

  get actionsPath(): AbstractControl {
    return this.creationFormGroup.get('actionsPath') as AbstractControl;
  }

  get actualResult(): AbstractControl {
    return this.creationFormGroup.get('actualResult') as AbstractControl;
  }

  get expectedResult(): AbstractControl {
    return this.creationFormGroup.get('expectedResult') as AbstractControl;
  }

  get workaroundAvailable(): AbstractControl {
    return this.creationFormGroup.get('workaroundAvailable') as AbstractControl;
  }

  get workaroundDescription(): AbstractControl {
    return this.creationFormGroup.get('workaroundDescription') as AbstractControl;
  }

  get customerImpactLevel(): AbstractControl {
    return this.creationFormGroup.get('customerImpactLevel') as AbstractControl;
  }

  get financialRiskLevel(): AbstractControl {
    return this.creationFormGroup.get('financialRiskLevel') as AbstractControl;
  }

  async refreshSearchedTasks(): Promise<void> {
    await this.supportTasksStateService.refreshTasks(
      this.targetFilters.value,
      this.completedFilters.value,
      this.search?.value ?? '',
      this.getCompanyId(),
    );
  }

  async onSubmit(): Promise<void> {
    this.creationFormGroup.markAllAsTouched();
    if (this.creationFormGroup.valid) {
      try {
        const task: Task = await this.createTask();
        await this.updateTask(task);
        await this.uploadFiles(task, this.files);
        this.resetForm();
        await this.refreshSearchedTasks();
      } catch (e) {
        this.logger.error(e);
      }
    }
  }

  // TODO Put in task module when available
  async createTask(): Promise<Task> {
    return await lastValueFrom(
      this.http.post<Task>(`${AppConfig.settings.legacyApiServerUrl}/tasks`, {
        companyId: this.userStateService.loggedInUserCompanyId,
        code: 'tech:support',
        metadata: {
          activeUserId: this.userStateService.activeUser.id,
          activeCompanyId: this.companyStateService.activeCompany.id,
          activeUrl: window.location.href,
        },
      }),
    );
  }

  // TODO Put in task module when available
  async updateTask(task: Task): Promise<void> {
    await lastValueFrom(
      this.http.post(`${AppConfig.settings.legacyApiServerUrl}/tasks/${task.id}`, {
        formData: this.creationFormGroup.value,
        companyId: this.userStateService.loggedInUserCompanyId,
        metadata: {
          activeCompanyId: this.companyStateService.activeCompany.id,
          activeUserId: this.userStateService.activeUser.id,
          activeUrl: this.window.location.href,
        },
        isSaving: true,
      }),
    );
  }

  // TODO Put in task module when available
  async uploadFiles(task: Task, files?: File[]): Promise<void> {
    if (files) {
      await Promise.all(
        files.map((file: File) => {
          const formData: FormData = new FormData();
          formData.append('file', file);
          return lastValueFrom(
            this.http.post(
              `${AppConfig.settings.legacyApiServerUrl}/tasks/${task.id}/attachments?filename=${file.name}&metadata={}&type=attachment`,
              formData,
            ),
          );
        }),
      );
    }
  }

  resetForm(): void {
    this.creationFormGroup.reset({
      workaroundAvailable: true,
    });

    this.tempAttachments = [];
  }

  async openTaskModal(event: MouseEvent, task: Task): Promise<void> {
    event.preventDefault();
    await this.openTaskModalService.openTaskModal(task?.id);
    await this.refreshSearchedTasks();
  }

  onUploadFiles(files: FileList) {
    this.files = [...this.files, ...Array.from(files)];
    this.tempAttachments = this.fileService.getTempAttachmentsFromFiles(this.files);
  }

  onDeleteFile(attachment: Attachment): void {
    this.files = this.fileService.removeFromFileList(this.files, this.tempAttachments, attachment);
    this.tempAttachments = this.fileService.removeFromTempAttachment(this.tempAttachments, attachment);
  }

  private getCompanyId(): number | undefined {
    if (this.targetFilters.value === 'activeCompany') {
      return this.companyStateService.activeCompany?.id;
    } else if (this.targetFilters.value === 'own') {
      return this.userStateService.loggedInUserCompanyId;
    }
    return undefined;
  }
}
