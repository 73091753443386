import { ViewportScroller } from '@angular/common';
import { Injectable, OnDestroy } from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { filter, pairwise, Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ScrollOnNavigationService implements OnDestroy {
  private routerSubscription!: Subscription;

  constructor(
    private readonly router: Router,
    private readonly viewportScroller: ViewportScroller,
  ) {
    // Disable automatic scroll restoration to avoid race conditions
    this.viewportScroller.setHistoryScrollRestoration('manual');
  }

  /**
   * When route is changed, Angular interprets a simple query params change as "forward navigation" too.
   * Using the pairwise function allows us to have both the previous and current router events, which we can
   * use to effectively compare the two navigation events and see if they actually change route, or only
   * the route parameters (i.e. selections stored in query params).
   *
   * Related to: https://github.com/angular/angular/issues/26744
   */
  public handleScrollOnNavigation(): void {
    this.routerSubscription = this.router.events
      .pipe(
        filter((e): e is Scroll => e instanceof Scroll),
        pairwise(),
      )
      .subscribe((e: Scroll[]) => {
        const previous: Scroll = e[0];
        const current: Scroll = e[1];
        if (current.position) {
          // Backward navigation
          this.viewportScroller.scrollToPosition(current.position);
        } else if (current.anchor) {
          // Anchor navigation
          this.viewportScroller.scrollToAnchor(current.anchor);
        } else if (previous.routerEvent instanceof NavigationEnd && current.routerEvent instanceof NavigationEnd) {
          // Check if routes match, or if it is only a query param change
          const previousBaseRoute: string = this.getBaseRoute(previous.routerEvent.urlAfterRedirects);
          const currentBaseRoute: string = this.getBaseRoute(current.routerEvent.urlAfterRedirects);
          if (
            previousBaseRoute !== currentBaseRoute &&
            (!previousBaseRoute.includes('cockpit') || !currentBaseRoute.includes('cockpit'))
          ) {
            // Routes don't match, this is actual forward navigation
            // Default behavior: scroll to top
            this.viewportScroller.scrollToPosition([0, 0]);
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.routerSubscription?.unsubscribe();
  }

  private getBaseRoute(url: string): string {
    // return url without query params
    return url.split('?')[0];
  }
}
