<ng-container *ngIf="supportModalComponentService.workaroundAvailableValueChanges$ | async"></ng-container>
<ng-container *ngIf="supportModalComponentService.searchFormGroupValueChanges$ | async"></ng-container>
<div dougsModalTitle>
  <h6>Support</h6>
  <i class="fal fa-times" dougsModalClose></i>
</div>
<div dougsModalContent>
  <ng-container *ngIf="!modeCreation">
    <div [formGroup]="supportModalComponentService.searchFormGroup" class="filters mb-16">
      <div class="filters__selects mb-8">
        <dougs-select dougsFormFieldControl formControlName="targetFilters">
          <dougs-select-option value="activeCompany">Tickets du dossier</dougs-select-option>
          <dougs-select-option value="own">Mes tickets</dougs-select-option>
          <dougs-select-option value="all">Tous les tickets</dougs-select-option>
        </dougs-select>
        <dougs-select dougsFormFieldControl formControlName="completedFilters">
          <dougs-select-option value="actives">Actifs</dougs-select-option>
          <dougs-select-option value="completed">Complétés</dougs-select-option>
        </dougs-select>
      </div>
      <dougs-form-field>
        <input dougsFormFieldControl formControlName="search" placeholder="Rechercher" type="text" />
      </dougs-form-field>
    </div>
    <ng-container *ngIf="supportTasksStateService.tasks$ | async as tasks">
      <dougs-blank-state *ngIf="tasks.length === 0 && !(supportModalComponentService.isLoading$ | async)">
        Aucun ticket
      </dougs-blank-state>
      <ul *ngIf="tasks.length > 0 && !(supportModalComponentService.isLoading$ | async)">
        @for (task of tasks; track 'id') {
          <li>
            <a (click)="supportModalComponentService.openTaskModal($event, task)" class="py-16 px-8" href="#">
              <div>
                <div class="task-title">
                  <i *ngIf="task.unseen" class="fas notification fa-circle mr-4 color-primary"></i>
                  <h6 class="mr-16">
                    {{ task.title }}
                  </h6>
                </div>
                <p class="tiny" *ngIf="task.lastCommentAt">Il y a {{ task.lastCommentAt | formatDistanceToNow }}</p>
              </div>
              <dougs-avatar
                *ngIf="task.assignee"
                [dougsTooltip]="task.assignee.profile.fullName"
                [image]="task.assignee.profile.avatarUrl || task.assignee.profile.alternateAvatarUrl"
              ></dougs-avatar>
            </a>
          </li>
        }
      </ul>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="modeCreation">
    <form [formGroup]="supportModalComponentService.creationFormGroup">
      <dougs-form-field>
        <label dougsFormFieldLabel for="title">Titre</label>
        <input dougsFormFieldControl formControlName="title" id="title" placeholder="Titre du ticket..." type="text" />
        <span *ngIf="formService.isControlInvalid(supportModalComponentService.title)" dougsFormFieldError></span>
      </dougs-form-field>
      <dougs-form-field>
        <label dougsFormFieldLabel for="actionsPath">J'ai fait les actions suivantes...</label>
        <textarea
          dougsFormFieldControl
          formControlName="actionsPath"
          id="actionsPath"
          placeholder="Quel écran ? Quel module ? Quelles actions pour reproduire le problème ?"
        ></textarea>
        <span *ngIf="formService.isControlInvalid(supportModalComponentService.actionsPath)" dougsFormFieldError></span>
      </dougs-form-field>
      <dougs-form-field>
        <label dougsFormFieldLabel for="actualResult">J'ai obtenu le résultat suivant...</label>
        <textarea
          dougsFormFieldControl
          formControlName="actualResult"
          id="actualResult"
          placeholder="Quelle erreur ? Quel dysfonctionnement obtenu ?"
        ></textarea>
        <span
          *ngIf="formService.isControlInvalid(supportModalComponentService.actualResult)"
          dougsFormFieldError
        ></span>
      </dougs-form-field>
      <div class="file-list">
        <dougs-file-input
          (uploadFiles)="supportModalComponentService.onUploadFiles($event)"
          fileInputText="Attacher une capture d'écran"
        ></dougs-file-input>
        <dougs-file-pill
          (deleteFile)="supportModalComponentService.onDeleteFile($event)"
          *ngFor="let attachment of supportModalComponentService.tempAttachments"
          [attachment]="attachment"
        ></dougs-file-pill>
      </div>
      <dougs-form-field>
        <label class="mt-16" dougsFormFieldLabel for="expectedResult">J'aurais voulu...</label>
        <textarea
          dougsFormFieldControl
          formControlName="expectedResult"
          id="expectedResult"
          placeholder="Décrire le comportement, le résultat correct. Le calcul qui aurait été juste."
        ></textarea>
        <span
          *ngIf="formService.isControlInvalid(supportModalComponentService.expectedResult)"
          dougsFormFieldError
        ></span>
      </dougs-form-field>
      <dougs-form-field>
        <label dougsFormFieldLabel>J'ai une solution de contournement</label>
        <dougs-checkbox [showYesNo]="true" appearance="toggle" formControlName="workaroundAvailable"></dougs-checkbox>
      </dougs-form-field>
      <dougs-form-field>
        <textarea
          dougsFormFieldControl
          formControlName="workaroundDescription"
          id="workaroundDescription"
          placeholder="Décrire comment vous avez contourné le problème pour avancer..."
        ></textarea>
        <span
          *ngIf="formService.isControlInvalid(supportModalComponentService.workaroundDescription)"
          dougsFormFieldError
        ></span>
      </dougs-form-field>
      <dougs-form-field>
        <label dougsFormFieldLabel>Impact client/collaborateur</label>
        <dougs-select dougsFormFieldControl formControlName="customerImpactLevel" placeholder="Sélectionner l'impact">
          <dougs-select-option value="severe">Fort</dougs-select-option>
          <dougs-select-option value="moderate">Moyen</dougs-select-option>
          <dougs-select-option value="low">Faible</dougs-select-option>
        </dougs-select>
        <span
          *ngIf="formService.isControlInvalid(supportModalComponentService.customerImpactLevel)"
          dougsFormFieldError
        ></span>
      </dougs-form-field>
      <dougs-form-field>
        <label dougsFormFieldLabel>Risque financier pour Dougs</label>
        <dougs-select dougsFormFieldControl formControlName="financialRiskLevel" placeholder="Sélectionner le risque">
          <dougs-select-option value="severe">Fort</dougs-select-option>
          <dougs-select-option value="moderate">Moyen</dougs-select-option>
          <dougs-select-option value="low">Faible</dougs-select-option>
        </dougs-select>
        <span
          *ngIf="formService.isControlInvalid(supportModalComponentService.financialRiskLevel)"
          dougsFormFieldError
        ></span>
      </dougs-form-field>
    </form>
  </ng-container>
  <dougs-loader *ngIf="supportModalComponentService.isLoading$ | async"></dougs-loader>
  <dougs-loader-fullpage *ngIf="isCreationLoading">Création en cours...</dougs-loader-fullpage>
</div>
<div dougsModalFooter>
  <dougs-button (click)="cancelOrClose()" color="secondary">
    {{ modeCreation ? 'Annuler la création' : 'Fermer' }}
  </dougs-button>
  <dougs-button (click)="modeCreation = true" *ngIf="!modeCreation">Créer un nouveau ticket</dougs-button>
  <dougs-button
    (click)="onSubmit()"
    *ngIf="modeCreation"
    [disabled]="!supportModalComponentService.creationFormGroup.valid"
  >
    Envoyer
  </dougs-button>
</div>
