<div
  *ngIf="accountingSurveyReminderComponentService.accountingSurveyReminderData$ | async as surveyReminderData"
  class="notification-reminder is-warning"
>
  <p class="color-gray-700">
    {{ userStateService.activeUserFirstName$ | async }}, il vous reste
    {{
      surveyReminderData.remainingDays === 0
        ? "jusqu'à ce soir minuit"
        : surveyReminderData.remainingDays === 1
          ? '1 jour'
          : surveyReminderData.remainingDays + ' jours'
    }}
    <span [innerHTML]="surveyReminderData.description"></span>
  </p>
  <a [routerLink]="URL.ACCOUNTING_SURVEY | createUrl | async">
    <p class="underline bold color-warning">Remplir le questionnaire</p>
  </a>
</div>
