import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import {
  ButtonComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalTitleDirective,
} from '@dougs/ds';
import { AddCompanyModalComponentService } from '../../services/modals/add-company-modal.component.service';
import { AddCompanyModalFirstStepComponent } from './add-company-modal-first-step/add-company-modal-first-step.component';
import { AddCompanyModalSecondStepComponent } from './add-company-modal-second-step/add-company-modal-second-step.component';
import { AddCompanyModalThirdStepComponent } from './add-company-modal-third-step/add-company-modal-third-step.component';

@Component({
  selector: 'dougs-add-company-modal',
  standalone: true,
  imports: [
    CommonModule,
    ModalTitleDirective,
    ModalCloseDirective,
    ModalContentDirective,
    AddCompanyModalFirstStepComponent,
    ModalFooterDirective,
    ButtonComponent,
    AddCompanyModalSecondStepComponent,
    ReactiveFormsModule,
    AddCompanyModalThirdStepComponent,
  ],
  templateUrl: './add-company-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [AddCompanyModalComponentService],
})
export class AddCompanyModalComponent {
  constructor(public readonly addCompanyModalComponentService: AddCompanyModalComponentService) {}
}
