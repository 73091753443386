import { Clipboard } from '@angular/cdk/clipboard';
import { HttpClient } from '@angular/common/http';
import { Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { combineLatest, concatMap, lastValueFrom, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { AppConfig } from '@dougs/core/config';
import { FlashMessagesService, ModalService, SelectModalComponent, SmsModalComponent } from '@dougs/ds';
import { LogoutService } from '@dougs/signin/shared';
import { Profile } from '@dougs/user/dto';
import { UserStateService } from '@dougs/user/shared';
import { AccountingFirmStateService } from '@dougs/white-label/shared';
import { AddCompanyModalComponent } from '../modals/add-company/add-company-modal.component';
import { ChangeCompanyLoaderModalComponent } from '../modals/change-company-loader-modal/change-company-loader-modal.component';

@Injectable()
export class UserMenuComponentService {
  private readonly openUserMenu: WritableSignal<boolean> = signal(false);
  userMenuOpened$: Signal<boolean> = this.openUserMenu.asReadonly();

  refreshCompanies$: Observable<void> = this.userStateService.activeUserIdChanged$.pipe(
    concatMap((activeUser) => this.userStateService.refreshCompanies(activeUser.id)),
  );

  constructor(
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly accountingFirmStateService: AccountingFirmStateService,
    private readonly clipboard: Clipboard,
    private readonly flashMessagesService: FlashMessagesService,
    private readonly modalService: ModalService,
    private readonly http: HttpClient,
    private readonly logoutService: LogoutService,
  ) {}

  toggleUserMenu(): void {
    this.openUserMenu.set(!this.openUserMenu());
  }

  closeUserMenu(): void {
    this.openUserMenu.set(false);
  }

  canManageStripe$: Observable<boolean> = combineLatest([
    this.userStateService.loggedInUser$,
    this.companyStateService.activeCompany$,
  ]).pipe(
    map(
      ([loggedInUser, activeCompany]) =>
        !!(
          loggedInUser?.flags.includes('canManageStripe') &&
          (activeCompany?.subscription?.activatedAt || activeCompany?.subscription?.cardBrand)
        ),
    ),
  );

  shouldShowInternalLinks$: Observable<boolean> = this.userStateService.loggedInUser$.pipe(
    map(
      (loggedInUser) =>
        loggedInUser.isAccountantOrAdmin &&
        this.accountingFirmStateService.isInternalAccountingFirm(loggedInUser.accountingFirmId),
    ),
  );

  async call(): Promise<void> {
    const activeCompany: Company = this.companyStateService.activeCompany;
    const profile: Profile | undefined = activeCompany.owner.profile;

    if (profile) {
      if (profile.normalizedPhones.length > 1) {
        const { data } = await lastValueFrom(
          this.modalService
            .open(SelectModalComponent, {
              data: {
                title: 'Sélectionner un numéro',
                items: profile.normalizedPhones.map((phone, index) => ({ label: phone, value: index })),
              },
            })
            .afterClosed$.pipe(take(1)),
        );

        if (data) {
          this.clipboard.copy(data as string);
          this.flashMessagesService.show('Copié dans le presse-papier !', {
            type: 'success',
          });
        }
      } else {
        this.clipboard.copy(profile.normalizedPhones[0]);
        this.flashMessagesService.show('Numéro copié dans le presse-papier !', {
          type: 'success',
        });
      }
    }
  }

  async sendSms(): Promise<void> {
    const activeCompany: Company = this.companyStateService.activeCompany;
    const profile: Profile | undefined = activeCompany.owner.profile;
    if (profile) {
      const phoneIndex = await lastValueFrom(
        this.modalService
          .open(SelectModalComponent, {
            data: {
              title: 'Sélectionner un numéro',
              items: profile.normalizedPhones.map((phone, index) => ({ label: phone, value: index })),
            },
          })
          .afterClosed$.pipe(take(1)),
      );

      if (phoneIndex.data !== '') {
        const message = await lastValueFrom(this.modalService.open(SmsModalComponent).afterClosed$.pipe(take(1)));

        if (message.data) {
          await lastValueFrom(
            this.http.post(
              `${AppConfig.settings.legacyApiServerUrl}/users/${activeCompany.owner.id}/profile/actions/send-sms?index=${phoneIndex.data}`,
              { message: message.data },
            ),
          );
        }
      }
    }
  }

  openChangeCompanyLoaderModal(companyId: number): void {
    if (this.companyStateService.activeCompany.id !== companyId) {
      this.closeUserMenu();
      this.modalService.open(ChangeCompanyLoaderModalComponent, {
        disableClose: true,
      });
    }
  }

  openAddCompanyModal(): void {
    this.modalService.open(AddCompanyModalComponent);
  }

  async openServicesModal(): Promise<void> {
    const { ServicesModalComponent } = await import('@dougs/subscription/ui');
    this.modalService.open(ServicesModalComponent, {
      data: {
        isSelectable: false,
        isInUserMenu: true,
      },
    });
  }

  goToLogout(): void {
    this.logoutService.goToLogout();
  }
}
