import { animate, keyframes, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { DividerComponent, PillComponent, TrackByPipe } from '@dougs/ds';
import {
  AccountingOnboardingChecklistCategoryWithColors,
  ChecklistComponentService,
} from '../../services/checklist.component.service';
import { ChecklistTipComponent } from '../checklist-tip/checklist-tip.component';

const ANIMATION = '300ms ease-in-out';

@Component({
  selector: 'dougs-checklist-category',
  standalone: true,
  imports: [CommonModule, ChecklistTipComponent, TrackByPipe, PillComponent, DividerComponent],
  templateUrl: './checklist-category.component.html',
  styleUrls: ['./checklist-category.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [
    trigger('slideInOut', [
      transition(':leave', [
        animate(
          ANIMATION,
          keyframes([
            style({ transform: 'translateX({{ position }})', marginTop: '*', opacity: 0, offset: 0.5 }),
            style({ height: 0, marginTop: 0, offset: 1 }),
          ]),
        ),
      ]),
    ]),
  ],
})
export class ChecklistCategoryComponent {
  @Input() category!: AccountingOnboardingChecklistCategoryWithColors;
  @Input() isLast = false;

  constructor(public readonly checklistComponentService: ChecklistComponentService) {}
}
