import { Injectable } from '@angular/core';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';
import { ModalService } from '@dougs/ds';
import { CompanyServicesStateService } from '@dougs/revenue/services/shared';
import { UserStateService } from '@dougs/user/shared';

@Injectable()
export class TrialComponentService {
  trialRemainingDays$: Observable<number | undefined> = this.companyServicesStateService.services$.pipe(
    map((services) => services?.accounting?.trialRemainingDays),
  );

  constructor(
    private readonly companyServicesStateService: CompanyServicesStateService,
    private readonly modalService: ModalService,
    private readonly metricsService: MetricsService,
    private readonly companyStateService: CompanyStateService,
    private readonly userStateService: UserStateService,
  ) {}

  async onShowPlans(): Promise<void> {
    const { PlansModalComponent } = await import('@dougs/subscription/ui');

    this.metricsService.pushMixpanelEvent('Menu Accounting Display Plans Clicked', {
      'Settings Completion Percent': this.companyStateService.activeCompany?.completion?.percent,
    });

    await lastValueFrom(
      this.modalService.open(PlansModalComponent, {
        data: {
          company: this.companyStateService.activeCompany,
          activeUser: this.userStateService.activeUser,
        },
      }).afterClosed$,
    );
  }
}
