<form [formGroup]="addCompanyModalComponentService.formGroup" (ngSubmit)="addCompanyModalComponentService.onSubmit()">
  <div dougsModalTitle *ngIf="addCompanyModalComponentService.currentStep$() !== 3">
    <h6>{{ addCompanyModalComponentService.currentStep$() === 1 ? 'Ajouter' : 'Lier' }} une entreprise</h6>
    <i class="fal fa-times" dougsModalClose></i>
  </div>
  <div dougsModalContent>
    <dougs-add-company-modal-first-step *ngIf="addCompanyModalComponentService.currentStep$() === 1" />
    <dougs-add-company-modal-second-step *ngIf="addCompanyModalComponentService.currentStep$() === 2" />
    <dougs-add-company-modal-third-step *ngIf="addCompanyModalComponentService.currentStep$() === 3" />
  </div>
  <div dougsModalFooter *ngIf="addCompanyModalComponentService.currentStep$() === 2">
    <dougs-button type="submit">Lier mon entreprise</dougs-button>
  </div>
</form>
