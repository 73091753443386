import { Pipe, PipeTransform } from '@angular/core';
import { PresenceMember } from '@dougs/core/socket';

@Pipe({
  name: 'presenceMemberTooltip',
  standalone: true,
})
export class PresenceMemberTooltipPipe implements PipeTransform {
  transform(presenceMembers: PresenceMember[], role: string): string {
    if (role === 'customer') {
      return `${presenceMembers.length} collaborateur${presenceMembers.length > 1 ? 's' : ''} Dougs travaille${presenceMembers.length > 1 ? 'nt' : ''} sur votre dossier`;
    }
    return presenceMembers.map((presenceMember) => presenceMember.info.fullName).join('<br />');
  }
}
