import { Pipe, PipeTransform } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { PresenceMember } from '@dougs/core/socket';
import { UserStateService } from '@dougs/user/shared';

@Pipe({
  name: 'activeUserConnected',
  standalone: true,
})
export class ActiveUserConnectedPipe implements PipeTransform {
  constructor(private readonly userStateService: UserStateService) {}

  transform(presenceMembers: Observable<PresenceMember[]>): Observable<boolean> {
    return combineLatest([
      presenceMembers,
      this.userStateService.activeUser$,
      this.userStateService.loggedInUser$,
    ]).pipe(
      map(([presenceMembers, activeUser, loggedInUser]) => {
        if (activeUser.id === loggedInUser.id) {
          return false;
        }
        return presenceMembers.some((presenceMember) => Number(presenceMember.id) === activeUser.id);
      }),
    );
  }
}
