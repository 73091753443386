<div *ngIf="tip" [ngClass]="{ archived: tip.archivedAt }" (click)="tipClicked.emit()" class="tip pointer p-8">
  <div
    [dougsTooltip]="tip.archivedAt ? 'Déplacer dans les conseils en cours' : 'Marquer comme terminé'"
    (click)="$event.stopPropagation(); checkboxClicked.emit()"
    dougsTooltipDelay="400"
    class="tip__check"
  >
    <svg width="12px" height="9px" viewbox="0 0 12 9">
      <polyline points="1 5 4 8 11 1"></polyline>
    </svg>
  </div>
  <h6 [innerHTML]="tip.title" class="tip__title"></h6>
  <div class="tip__chevron">
    <i class="fal fa-chevron-right color-primary-700"></i>
  </div>
</div>
