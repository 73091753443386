import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostListener, Input } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CompanyStateService } from '@dougs/company/shared';
import { ReplaceActiveCompanyIdInUrlPipe, RoutingPipe, URL } from '@dougs/core/routing';
import { PresenceMemberStateService } from '@dougs/core/socket';
import {
  AvatarComponent,
  ClickOutsideDirective,
  CopyToClipboardDirective,
  DropdownComponent,
  DropdownOptionComponent,
  DropdownTriggerDirective,
  TooltipDirective,
} from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { ActiveUserConnectedPipe } from '../../pipes/active-user-connected.pipe';
import { UserMenuComponentService } from '../../services/user-menu.component.service';
import { UserMenuDropdownComponent } from './user-menu-dropdown/user-menu-dropdown.component';

@Component({
  selector: 'dougs-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent,
    TooltipDirective,
    DropdownTriggerDirective,
    DropdownComponent,
    DropdownOptionComponent,
    RouterModule,
    CopyToClipboardDirective,
    RoutingPipe,
    ReplaceActiveCompanyIdInUrlPipe,
    ClickOutsideDirective,
    UserMenuDropdownComponent,
    ActiveUserConnectedPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [UserMenuComponentService],
})
export class UserMenuComponent {
  @Input() isCompact = false;
  protected readonly URL = URL;

  @HostListener('window:keydown.escape')
  closeUserMenu(): void {
    this.userMenuComponentService.closeUserMenu();
  }

  constructor(
    public readonly userMenuComponentService: UserMenuComponentService,
    public readonly userStateService: UserStateService,
    public readonly companyStateService: CompanyStateService,
    public readonly presenceMemberStateService: PresenceMemberStateService,
  ) {}
}
