import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormService } from '@dougs/core/form';
import {
  AvatarComponent,
  BlankStateComponent,
  ButtonComponent,
  CheckboxComponent,
  ControlFormFieldDirective,
  FileInputComponent,
  FilePillComponent,
  FormatDistanceToNowPipe,
  FormFieldComponent,
  LabelFormFieldDirective,
  LoaderComponent,
  LoaderFullpageComponent,
  ModalCloseDirective,
  ModalContentDirective,
  ModalFooterDirective,
  ModalRef,
  ModalTitleDirective,
  SelectComponent,
  SelectOptionComponent,
  TooltipDirective,
} from '@dougs/ds';
import { SupportTasksStateService } from '@dougs/task/shared';
import { SupportModalComponentService } from '../../services/modals/support-modal.component.service';

@Component({
  selector: 'dougs-support-modal',
  templateUrl: './support-modal.component.html',
  styleUrls: ['./support-modal.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SelectComponent,
    SelectOptionComponent,
    FormFieldComponent,
    BlankStateComponent,
    AvatarComponent,
    TooltipDirective,
    LabelFormFieldDirective,
    ControlFormFieldDirective,
    FileInputComponent,
    FilePillComponent,
    CheckboxComponent,
    LoaderComponent,
    LoaderFullpageComponent,
    ButtonComponent,
    ModalFooterDirective,
    ModalContentDirective,
    ModalCloseDirective,
    ModalTitleDirective,
    FormatDistanceToNowPipe,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [SupportModalComponentService],
})
export class SupportModalComponent {
  modeCreation = false;
  isCreationLoading = false;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly modalRef: ModalRef,
    public readonly formService: FormService,
    public readonly supportModalComponentService: SupportModalComponentService,
    public readonly supportTasksStateService: SupportTasksStateService,
  ) {}

  async onSubmit(): Promise<void> {
    this.isCreationLoading = true;
    await this.supportModalComponentService.onSubmit();
    this.isCreationLoading = false;
    this.modeCreation = false;
    this.cdr.markForCheck();
  }

  cancelOrClose(): void {
    if (this.modeCreation) {
      this.modeCreation = false;
      this.supportModalComponentService.resetForm();
    } else {
      this.modalRef.close();
    }
  }
}
