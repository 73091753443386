import { computed, Injectable, signal, Signal, WritableSignal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { map } from 'rxjs/operators';
import {
  AccountingOnboardingChecklistCategory,
  AccountingOnboardingChecklistTip,
} from '@dougs/accounting/onboarding/dto';
import { AccountingOnboardingStateService } from '@dougs/accounting/onboarding/shared';
import { Company } from '@dougs/company/dto';
import { CompanyStateService } from '@dougs/company/shared';
import { MetricsService } from '@dougs/core/metrics';

export enum ChecklistTab {
  UNARCHIVED = 'UNARCHIVED',
  ARCHIVED = 'ARCHIVED',
}

export interface AccountingOnboardingChecklistCategoryWithColors extends AccountingOnboardingChecklistCategory {
  color: 'primary' | 'secondary' | 'success' | 'warning' | 'error' | 'white';
}

@Injectable()
export class ChecklistComponentService {
  constructor(
    private readonly accountingOnboardingStateService: AccountingOnboardingStateService,
    private readonly companyStateService: CompanyStateService,
    private readonly metricsService: MetricsService,
  ) {}

  private readonly categoriesColors: Array<'primary' | 'secondary' | 'success' | 'warning' | 'error' | 'white'> = [
    'success',
    'error',
    'primary',
    'warning',
    'secondary',
    'white',
  ];
  private readonly currentTab: WritableSignal<ChecklistTab> = signal<ChecklistTab>(ChecklistTab.UNARCHIVED);
  private readonly currentTip: WritableSignal<AccountingOnboardingChecklistTip | undefined> = signal<
    AccountingOnboardingChecklistTip | undefined
  >(undefined);
  readonly categories: Signal<AccountingOnboardingChecklistCategoryWithColors[]> = toSignal(
    this.accountingOnboardingStateService.accountingOnboarding$.pipe(
      map((onboarding) => this.mapCategoriesWithColors(onboarding?.data.checklist?.categories ?? [])),
    ),
    { initialValue: [] },
  );
  private readonly processingTipTypes: WritableSignal<string[]> = signal<string[]>([]);
  private readonly areTipAnimationsDisabled: WritableSignal<boolean> = signal<boolean>(false);

  readonly ANIMATION_DURATION = 300;

  unarchivedTipsCategories$: Signal<AccountingOnboardingChecklistCategoryWithColors[]> = computed(() =>
    this.filterCategoriesListByTipCondition((tip) => !tip.archivedAt, this.categories()),
  );
  unarchivedTipsCount$: Signal<number> = computed(() =>
    this.unarchivedTipsCategories$().reduce((acc, category) => acc + category.tips.length, 0),
  );
  archivedTipsCategories$: Signal<AccountingOnboardingChecklistCategoryWithColors[]> = computed(() =>
    this.filterCategoriesListByTipCondition((tip) => !!tip.archivedAt, this.categories()),
  );
  archivedTipsCount$: Signal<number> = computed(() =>
    this.archivedTipsCategories$().reduce((acc, category) => acc + category.tips.length, 0),
  );
  currentTab$: Signal<ChecklistTab> = this.currentTab.asReadonly();
  currentTip$: Signal<AccountingOnboardingChecklistTip | undefined> = this.currentTip.asReadonly();
  processingTipTypes$: Signal<string[]> = this.processingTipTypes.asReadonly();
  areTipAnimationsDisabled$: Signal<boolean | undefined> = this.areTipAnimationsDisabled.asReadonly();

  async handleTipClick(tip: AccountingOnboardingChecklistTip): Promise<void> {
    if (!this.processingTipTypes$().includes(tip.type)) {
      this.processingTipTypes.update((tipTypes) => [...tipTypes, tip.type]);

      await this.archiveOrUnarchiveTip(tip, this.companyStateService.activeCompany);

      this.removeProcessingTipType(tip.type);
      this.closeTip();
    }
  }

  handleTabClick(tab: ChecklistTab): void {
    this.areTipAnimationsDisabled.set(true);
    this.currentTab.set(tab);

    setTimeout(() => {
      this.areTipAnimationsDisabled.set(false);
    }, this.ANIMATION_DURATION);
  }

  openTip(tip: AccountingOnboardingChecklistTip): void {
    this.metricsService.pushMixpanelEvent(`Checklist ${tip.type} Link Clicked`, { eventTitle: tip.title });
    this.currentTip.set(tip);
  }

  closeTip(): void {
    this.currentTip.set(undefined);
  }

  trackByIndex(index: number): number {
    return index;
  }

  private archiveOrUnarchiveTip(tip: AccountingOnboardingChecklistTip, company: Company): Promise<void> {
    if (tip.archivedAt) {
      return this.accountingOnboardingStateService.unarchiveChecklistTip(company.id, tip.type);
    }

    this.metricsService.pushMixpanelEvent(`Checklist ${tip.type} Archived`, { eventTitle: tip.title });
    return this.accountingOnboardingStateService.archiveChecklistTip(company.id, tip.type);
  }

  private mapCategoriesWithColors(
    categories: AccountingOnboardingChecklistCategory[],
  ): AccountingOnboardingChecklistCategoryWithColors[] {
    return categories.map((category, index) => ({
      ...category,
      color: this.categoriesColors[index % this.categoriesColors.length],
    }));
  }

  private filterCategoriesListByTipCondition(
    tipCondition: (tip: AccountingOnboardingChecklistTip) => boolean,
    categories: AccountingOnboardingChecklistCategoryWithColors[],
  ): AccountingOnboardingChecklistCategoryWithColors[] {
    return categories
      .map((category) => ({ ...category, tips: category.tips.filter(tipCondition) }))
      .filter((category) => category.tips.length > 0);
  }

  private removeProcessingTipType(tipTypeToRemove: string): void {
    this.processingTipTypes.update((tipTypes) => tipTypes.filter((tipType) => tipType !== tipTypeToRemove));
  }
}
