import { Injectable, OnDestroy, Renderer2, RendererFactory2 } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { CompanyStateService } from '@dougs/company/shared';
import { AppConfig } from '@dougs/core/config';
import { UserStateService } from '@dougs/user/shared';
import { InteractionsHttpService } from './interactions.http';

@Injectable({
  providedIn: 'root',
})
export class InteractionsService implements OnDestroy {
  private readonly renderer2: Renderer2;
  private unlistenerMousemove?: () => void;
  private unlistenerKeyup?: () => void;
  private unlistenerKeydown?: () => void;
  private throttleTimeout: any = null;

  constructor(
    private readonly rendererFactory: RendererFactory2,
    private readonly interactionsHttpService: InteractionsHttpService,
    private readonly userStateService: UserStateService,
    private readonly companyStateService: CompanyStateService,
  ) {
    this.renderer2 = this.rendererFactory.createRenderer(null, null);
  }

  addInteractions(): void {
    if (AppConfig.settings.enableInteractions) {
      if (!this.unlistenerMousemove) {
        this.unlistenerMousemove = this.renderer2.listen('body', 'mousemove', () => {
          this.throttleEvent();
        });
      }

      if (!this.unlistenerKeyup) {
        this.unlistenerKeyup = this.renderer2.listen('body', 'keyup', () => {
          this.throttleEvent();
        });
      }

      if (!this.unlistenerKeydown) {
        this.unlistenerKeydown = this.renderer2.listen('body', 'keydown', () => {
          this.throttleEvent();
        });
      }
    }
  }

  throttleEvent(): void {
    if (this.throttleTimeout) {
      return;
    }

    this.throttleTimeout = setTimeout(async () => {
      this.throttleTimeout = null;
      if (
        this.userStateService?.loggedInUser &&
        this.userStateService?.activeUser &&
        this.companyStateService?.activeCompany
      ) {
        await lastValueFrom(
          this.interactionsHttpService.postInteractions(
            this.userStateService.loggedInUser.id,
            this.userStateService.activeUser,
            this.companyStateService.activeCompany,
          ),
        );
      }
    }, 10000);
  }

  ngOnDestroy(): void {
    if (this.unlistenerMousemove) {
      this.unlistenerMousemove();
    }

    if (this.unlistenerKeyup) {
      this.unlistenerKeyup();
    }

    if (this.unlistenerKeydown) {
      this.unlistenerKeydown();
    }
  }
}
