import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PresenceMemberStateService } from '@dougs/core/socket';
import { AvatarComponent, TooltipDirective, TrackByPipe } from '@dougs/ds';
import { UserStateService } from '@dougs/user/shared';
import { PresenceMemberSlicePipe } from '../pipes/presence-member-slice.pipe';
import { PresenceMemberTooltipPipe } from '../pipes/presence-member-tooltip.pipe';
import { PresenceMembersComponentService } from '../services/presence-members.component.service';

@Component({
  selector: 'dougs-presence-members',
  standalone: true,
  imports: [
    CommonModule,
    AvatarComponent,
    TrackByPipe,
    TooltipDirective,
    PresenceMemberTooltipPipe,
    PresenceMemberSlicePipe,
  ],
  templateUrl: './presence-members.component.html',
  styleUrl: './presence-members.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PresenceMembersComponentService],
})
export class PresenceMembersComponent {
  @Input() isCompact = false;

  constructor(
    public readonly presenceMembersComponentService: PresenceMembersComponentService,
    public readonly presenceMemberStateService: PresenceMemberStateService,
    public readonly userStateService: UserStateService,
  ) {}
}
