<ng-container *ngIf="presenceMembersComponentService.presenceMembersToShow$ | async as presenceMembersToShow">
  <div
    class="presence-members"
    [dougsTooltip]="presenceMembersToShow | presenceMemberTooltip: userStateService.loggedInUser.role"
    *ngIf="presenceMembersComponentService.shouldShowPresenceMember$ | async"
  >
    <ng-container *ngIf="presenceMemberStateService.presenceMembers$ | async as presenceMembers">
      <ng-container *ngIf="presenceMembers.length > 1">
        <dougs-avatar
          [ngStyle]="{ 'z-index': presenceMembersToShow.length - i }"
          *ngFor="
            let member of presenceMembersToShow | slice: 0 : (presenceMembersToShow | presenceMemberSlice: isCompact);
            trackBy: 'id' | trackBy;
            let i = index;
            let first = first
          "
          [image]="member.info.avatarUrl"
          size="small"
          [ngClass]="{ 'is-not-first': !first }"
        ></dougs-avatar>
        <div
          [ngStyle]="{ 'z-index': isCompact ? -1 : 1 }"
          class="presence-members__more-3-members"
          [ngClass]="{ 'is-compact': isCompact }"
          *ngIf="presenceMembersToShow.length > (isCompact ? 1 : 3)"
        >
          +{{ presenceMembersToShow.length - (isCompact ? 0 : 2) }}
        </div>
      </ng-container>
    </ng-container>
  </div>
</ng-container>
