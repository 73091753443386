import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { BehaviorSubject, delay, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ModalContentDirective, ModalRef } from '@dougs/ds';

@Component({
  selector: 'dougs-change-company-loader-modal',
  standalone: true,
  imports: [CommonModule, ModalContentDirective, LottieComponent],
  templateUrl: './change-company-loader-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./change-company-loader-modal.component.scss'],
})
export class ChangeCompanyLoaderModalComponent {
  subject: BehaviorSubject<number> = new BehaviorSubject(1);
  animation: AnimationOptions = {
    path: 'animations/loader-change-company.json',
  };

  constructor(private readonly modalRef: ModalRef) {}

  closeModal$: Observable<void> = this.subject.pipe(
    delay(5000),
    map(() => this.modalRef.close()),
  );
}
